@import 'normalize.css';

* {
  outline: none !important;
}

html {
  font-size: 62.5%;
  font-family: Roboto, Helvetica Neue, Arial, sans-serif;
  background-color: #262933;
}

body {
  font-size: 14px;
  line-height: 1.4;
}

textarea {
  overflow: hidden;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

h1,
.h1 {
  font-size: 24px;
}

.w-04 {
  width: 40%;
}
.mr-2 {
  margin-right: 2px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

code {
  padding: 10px;
  display: block;
  background-color: rgb(241, 241, 241);
  word-wrap: break-word;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role='button'] {
  text-decoration: none;
}

.vjs-playback-rate .vjs-playback-rate-value {
  font-size: 1.2em !important;
  line-height: 2;
  height: 0 !important;
  margin-top: 4px;
}

.RTE p,
.RTE div {
  margin-top: 14px;
  margin-bottom: 14px;
}

.channels-menu::-webkit-scrollbar {
  display: none;
}

.vjs-playback-rate {
  order: 2;
}

.vjs-fullscreen-control {
  order: 3;
}

.slick-track {
  margin-left: 0 !important;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0 !important;
}

.slick-prev:before,
.slick-next:before {
  opacity: 0 !important;
}

.ps__rail-y {
  display: none !important;
}

.ps {
  overflow: overlay !important;
}

.rt-light {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.ml-5 {
  margin-left: 5px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-25 {
  margin-top: 25px !important;
}

.ml--25 {
  margin-left: 25px;
}

.ml--15 {
  margin-left: 15px;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-35 {
  width: 35%;
}

.w-45 {
  width: 45%;
}

.w-40 {
  width: 40%;
}
.w-30 {
  width: 30%;
}

.w-20 {
  width: 20%;
}

.w-25 {
  width: 25%;
}
.w--60 {
  width: 60%;
}

.w-15 {
  width: 15%;
}

.w-5 {
  width: 5%;
}

.w-75 {
  width: 75%;
}

.m-10 {
  margin: 10px !important;
}

.m-4 {
  margin: 0.4rem;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.w-10 {
  width: 10%;
}

.w-80 {
  width: 80%;
}

.w-45 {
  width: 45%;
}

.m-20 {
  margin: 20px;
}

.ml--auto {
  margin-left: auto;
}

.mr-5 {
  margin-right: 5px;
}

.ds-flex {
  display: flex;
}

.mr-14 {
  margin-right: 1.4rem;
}

.fs--15 {
  font-size: 15px;
}

.mr-4 {
  margin-right: 4px;
}

.mt-22 {
  margin-top: 22px;
}

.mt-9 {
  margin-top: 9px !important;
}

.m-auto {
  margin: auto;
}

.mr-15 {
  margin-right: 15px;
}

.mt--8 {
  margin-top: 8px;
}

.mt--5 {
  margin-top: 5px;
}

.ds-block {
  display: block;
}

.m-25 {
  margin: 25px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-35 {
  margin-left: 35px;
}

.mb--20 {
  margin-bottom: 20px;
}
.mb--5 {
  margin-bottom: 5px;
}

.mr-30 {
  margin-right: 30;
}

.mb--100 {
  margin-bottom: 100px;
}

.mb--10 {
  margin-bottom: 10px;
}

.mt--15 {
  margin-top: -15px;
}

.pl-10 {
  padding-left: 10px;
}

.m-5 {
  margin: 5px;
}

.p-10 {
  padding: 10px;
}

.mb--25 {
  margin-bottom: 25px;
}

.p-20 {
  padding: 20px;
}

.bg-white {
  background: 'white';
}

.pt-10 {
  padding-top: 10px;
}

.mild-box-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.align-center {
  align-items: center;
}

.overflow--auto {
  overflow: auto;
}

.rst__rowContents {
  border: none !important;
  box-shadow: none !important;
  width: 100%;
}

.vjs-order-2 {
  order: 2;
  margin-right: -12px;
}

.rst__rowTitle {
  font-weight: 400;
  color: black;
  cursor: pointer;
}

.node__text {
  display: flex;
  margin: auto 0 auto 22px;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.rst__text__wrapper {
  padding-left: 0 !important;
}

.rst__text__wrapper_track {
  margin-left: 10px !important;
}

.rst__videoCount {
  border: 1px solid #b5b5b5;
  margin: 0 auto;
  display: flex;
  font-size: 10px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  line-height: 28px;
  margin: auto 0;
}

.child__text {
  display: flex;
}

.video-text-link {
  color: rgba(0, 0, 0);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical !important;
}

.video__text {
  margin-top: 4px;
  margin-left: 6px;
  /* width: 220px; */
  display: flex;
  white-space: normal;
}

.rst__collapseButton {
  margin-left: 24px;
  z-index: 999;
  width: 28px;
  height: 28px;
  background: rgb(204, 204, 204);
  box-shadow: none !important;
}

.rst__expandButton {
  margin-left: 24px;
  z-index: 999;
  width: 28px;
  height: 28px;
  background: #cccccc;
  box-shadow: none !important;
}

.rst__rowTitle {
  white-space: normal !important;
}

.rst__icon {
  cursor: pointer;
  position: absolute;
  margin-top: 16px;
  margin-left: 3.5px;
  color: white;
  font-size: 18px !important;
  z-index: 999;
  box-shadow: none !important;
}

.hide__line {
  position: absolute;
  width: 20px;
  height: 15px;
  background: white;
  z-index: 998;
  top: 5px;
  margin-left: -23px;
}

.rst__node {
  margin-left: -18px;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after,
.rst__lineChildren::after {
  background: #b5b5b5 !important;
  z-index: 100;
}

.icon__wrapper {
  visibility: hidden;
  margin: auto 0px auto auto;
  font-size: 23;
  height: 23;
  color: rgba(96, 96, 96);
}

/* .icon_wrapper:hover .icon_wrapper{
	visibility: 'inherit !important' as 'inherit';
} */

.rst__rowWrapper:hover .icon__wrapper {
  visibility: inherit;
}

.selected-bg {
  left: -71px;
  height: 49px;
  z-index: 0;
  width: 150%;
  position: absolute;
}

.custom-table .rt-td {
  border: 0px !important;
  padding: 7px 28px !important;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.custom-table .rt-th {
  border: 0px !important;
  height: 56px;
  padding: 5px 28px !important;
}

.custom-table .rt-resizable-header-content {
  justify-content: left !important;
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 500 !important;
}

.custom-table .rt-tbody {
  min-width: 1000px !important;
}

.custom-table .rt-thead {
  min-width: 1000px !important;
}

.custom-table .-odd {
  background: rgb(250, 250, 250) !important;
}

.notifications-tr {
  top: 70px !important;
}

.notifications-tr .notification {
  background: #fff !important;
  -webkit-box-shadow: 0 15px 24px 0 rgba(0, 0, 0, 0.1) !important;
  box-shadow: 0 15px 24px 0 rgba(0, 0, 0, 0.1) !important;
  border-top: 0 !important;
  padding: 0 !important;
  border-radius: 2px;
  overflow: hidden;
  background-color: #323335 !important;
}

.notifications-tr .notification-dismiss {
  background-color: #323335 !important;
  font-family: Arial !important;
  font-weight: 300 !important;
  font-size: 25px !important;
  color: #fff !important;
  top: 10px !important;
  right: 10px !important;
}

.custom-notification {
  padding: 0 10px 0 0;
  display: -ms-flexbox;
  display: flex;
}

.custom-notification__icon.success {
  /* background-color: #4cd964; */
  color: white;
}

.custom-notification__icon {
  float: left;
  width: 40px;
  padding: 10px 0;
  text-align: center;
}

.custom-notification__text {
  float: left;
  width: calc(100% - 60px);
  padding: 10px;
  font-size: 14px;
  background-color: #323335;
  color: white;
}

.vjs-fluid {
  overflow: hidden !important;
}

.vjs-poster {
  background-color: white;
}

.video-js .vjs-tech {
  background-color: white;
}

.video-js.vjs-fluid {
  background-color: white;
}

.border-none {
  border: none;
}

.h-40 {
  height: 40px;
}

.h-60 {
  height: 60px;
}

.h-35 {
  height: 35px;
}

.color-primary {
  color: rgb(156, 50, 150);
}

.visibility--hidden {
  visibility: hidden !important;
}

.react-select__control {
  border: none !important;
  box-shadow: none !important;
}

.react-select__value-container {
  border-bottom: 1px solid rgb(148, 148, 148);
}
.react-select__value-container:hover {
  border: none;
  border-bottom: 2px solid black !important;
}

.justify-end {
  justify-content: flex-end !important;
}

.flex-column {
  flex-direction: column !important;
}

.justify-space-between {
  justify-content: space-between !important;
}

.justify-center {
  justify-content: center !important;
}

.rs-sticky {
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  /* background: white */
}

.navigation {
  position: relative;
}

.navigation::after {
  content: '';
  border-bottom: 4px solid #e9ebee;
  position: absolute;
  top: 50%;
  left: 0;

  width: 100%;
  height: 1px;
}

.overflow-inherit {
  overflow: inherit !important;
}

.embedded-player {
  width: 100% !important;
  height: 100% !important;
}
.node__text {
  font-weight: 500;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical !important;
}

@import 'print.css';

@import 'tables.css';

@import 'react-table.css';
@import 'spinner.css';

@import 'prism.css';

@import 'fuse-helpers.css';

.Connect-SupportButton--content-1 {
  visibility: hidden !important;
}

.rdw-editor-main {
  border: 1px solid #eee;
  padding: 0px 10px;
}

.seek-marker {
  position: absolute;
  height: 10px;
  width: 10px;
  top: -4px;
  border-radius: 100%;
}

.rst__nodeContent {
  width: 100%;
}

.rt-resizable-header-content {
  width: 100%;
}
.disablednotoriginal {
  position: relative;
  top: 11px;
}
.disablednotoriginal::before {
  content: 'You can only use this with original video';
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 5;
  top: -30px;
}
.disablednotoriginal:hover::before {
  opacity: 1;
  visibility: visible;
}
