.inline-spinner {
    display: inline-block;
    line-height: 1;
  }
  .inline-spinner > span {
    width: 6px;
    height: 6px;
    margin: 0 1px;
    background-color: white;
    vertical-align: middle;
    line-height: 1;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }



  .inline-spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .inline-spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
   }
    40% {
      -webkit-transform: scale(1);
   }
  }
  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
   }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
   }
  }