.container-switch{
    margin-top: 10px;
    display: 'flex';
    justify-content: 'space-between';
    align-items: 'center';
}
.switch-container {
    width: 4rem;
    height: 1.2rem;
    border-radius: 5rem;
    position: relative;
    background: rgb(153 153 153);
}

.switch-container input, #switch{
    display: none;
}

.switch-container label {
    width: 2rem;
    height: 2rem;
    padding: 2px;
    border-radius: 50%;
    background: white;
    position: absolute;
    top: -4px;
    left: 0;
    z-index: 99;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.7s ease-in-out;
}


/* .switch-color {
    width: 3rem;
    height: 1.5rem;
    left: 0;
    top: 0;
    border-radius: 5rem;
    z-index: -1;
    position: absolute;
    transition: all 500ms cubic-bezier(0.34, 1.36, 0.2, 1);
} */

/* .switch-container input[type="checked"]::before, input[type="checked"]::after {
    transition: 0.7s ease-in-out;
} */

#switch:checked~label {
    transform: translatex(2rem) rotatez(360deg);
    
}

#switch~label {
    transition: all 1s cubic-bezier(0.34, 1.36, 0.2, 1);
}